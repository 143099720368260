/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-16qlsf9 --parallax pb--60 pt--60" name={"intro"} style={{"minHeight":"100vh"}} parallax={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/11212/1d5bf8d61b844f88b49598b3fa3d85c9_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/1d5bf8d61b844f88b49598b3fa3d85c9_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/1d5bf8d61b844f88b49598b3fa3d85c9_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/1d5bf8d61b844f88b49598b3fa3d85c9_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/1d5bf8d61b844f88b49598b3fa3d85c9_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/1d5bf8d61b844f88b49598b3fa3d85c9_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/1d5bf8d61b844f88b49598b3fa3d85c9_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/1d5bf8d61b844f88b49598b3fa3d85c9_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex js-anim  --anim6 --anim-s2 --center el--1 pb--80 pt--80" anim={"6"} animS={"2"} style={{"maxWidth":800,"backgroundColor":"rgba(241, 241, 241, 0.95)"}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--12 pt--06" anim={"7"} animS={"2"} style={{"maxWidth":480}}>
              
              <Title className="title-box fs--86 w--300 swpf--uppercase lh--1" content={"<br><br>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--center fs--22 w--300 ls--002 lh--1" style={{"maxWidth":378}} content={"<span style=\"font-style: italic;\">&nbsp; &nbsp; &nbsp; &nbsp;</span>"}>
              </Subtitle>

              <Title className="title-box fs--72 w--300 swpf--uppercase lh--1" content={""}>
              </Title>

              <Text className="text-box mt--60" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized. "}>
              </Text>

              <Title className="title-box title-box--style2 fs--36 w--300 swpf--uppercase lh--1 mt--60" content={""}>
              </Title>

              <Text className="text-box mt--20" content={"Created with <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}